// ==========================================================================
// Base – Helpers
// ==========================================================================

// General Resets
.no-margin { margin: 0; }
.no-padding { padding: 0; }
.no-float { float: none; }
.no-background { background: transparent; }
.no-border { border: 0; }
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

// Font Weights
.font-100 { font-weight: 100; }
.font-200 { font-weight: 200; }
.font-300 { font-weight: 300; }
.font-400 { font-weight: 400; }
.font-500 { font-weight: 500; }
.font-600 { font-weight: 600; }
.font-700 { font-weight: 700; }
.font-800 { font-weight: 800; }
.font-900 { font-weight: 900; }

// Font Styles
.font-normal { font-style: normal; }
.font-italic { font-style: italic; }

// Text Modifications
.uppercase { text-transform: uppercase; }
.lowercase { text-transform: lowercase; }
.capitalize { text-transform: capitalize; }


// Display Types
.none { display: none; }
.block { display: block; }
.inline-block { display: inline-block; }
.inline { display: inline; }



// Padding Helpers
.pad-top-5 { padding-top: 5px; }
.pad-top-10 { padding-top: 10px; }
.pad-top-15 { padding-top: 15px; }
.pad-top-20 { padding-top: 20px; }
.pad-top-25 { padding-top: 25px; }
.pad-top-30 { padding-top: 30px; }
.pad-top-35 { padding-top: 35px; }
.pad-top-40 { padding-top: 40px; }
.pad-top-45 { padding-top: 45px; }
.pad-top-50 { padding-top: 50px; }
.pad-top-55 { padding-top: 55px; }
.pad-top-60 { padding-top: 60px; }
.pad-bottom-5 { padding-bottom: 5px; }
.pad-bottom-10 { padding-bottom: 10px; }
.pad-bottom-15 { padding-bottom: 15px; }
.pad-bottom-20 { padding-bottom: 20px; }
.pad-bottom-25 { padding-bottom: 25px; }
.pad-bottom-30 { padding-bottom: 30px; }
.pad-bottom-35 { padding-bottom: 35px; }
.pad-bottom-40 { padding-bottom: 40px; }
.pad-bottom-45 { padding-bottom: 45px; }
.pad-bottom-50 { padding-bottom: 50px; }
.pad-bottom-55 { padding-bottom: 55px; }
.pad-bottom-60 { padding-bottom: 60px; }
.pad-5 { padding: 5px; }
.pad-10 { padding: 10px; }
.pad-15 { padding: 15px; }
.pad-20 { padding: 20px; }
.pad-25 { padding: 25px; }
.pad-30 { padding: 30px; }
.pad-35 { padding: 35px; }
.pad-40 { padding: 40px; }
.pad-45 { padding: 45px; }
.pad-50 { padding: 50px; }
.pad-55 { padding: 55px; }
.pad-60 { padding: 60px; }


// Tablet Helpers
@include breakpoint(medium) {

  // General Resets
  .no-float-m { float: none; }
  .no-padding-m { padding: 0; }
  .no-margin-m { margin: 0; }


  // Display Types
  .none-m { display: none; }
  .block-m { display: block; }
  .inline-block-m { display: inline-block; }
  .inline-m { display: inline; }


  // Padding Helpers
  .pad-top-0-m { padding-top: 0; }
  .pad-top-5-m { padding-top: 5px; }
  .pad-top-10-m { padding-top: 10px; }
  .pad-top-15-m { padding-top: 15px; }
  .pad-top-20-m { padding-top: 20px; }
  .pad-top-25-m { padding-top: 25px; }
  .pad-top-30-m { padding-top: 30px; }
  .pad-top-35-m { padding-top: 35px; }
  .pad-top-40-m { padding-top: 40px; }
  .pad-top-45-m { padding-top: 45px; }
  .pad-top-50-m { padding-top: 50px; }
  .pad-top-55-m { padding-top: 55px; }
  .pad-top-60-m { padding-top: 60px; }
  .pad-bottom-0-m { padding-bottom: 0; }
  .pad-bottom-5-m { padding-bottom: 5px; }
  .pad-bottom-10-m { padding-bottom: 10px; }
  .pad-bottom-15-m { padding-bottom: 15px; }
  .pad-bottom-20-m { padding-bottom: 20px; }
  .pad-bottom-25-m { padding-bottom: 25px; }
  .pad-bottom-30-m { padding-bottom: 30px; }
  .pad-bottom-35-m { padding-bottom: 35px; }
  .pad-bottom-40-m { padding-bottom: 40px; }
  .pad-bottom-45-m { padding-bottom: 45px; }
  .pad-bottom-50-m { padding-bottom: 50px; }
  .pad-bottom-55-m { padding-bottom: 55px; }
  .pad-bottom-60-m { padding-bottom: 60px; }
  .pad-0-m { padding: 0; }
  .pad-5-m { padding: 5px; }
  .pad-10-m { padding: 10px; }
  .pad-15-m { padding: 15px; }
  .pad-20-m { padding: 20px; }
  .pad-25-m { padding: 25px; }
  .pad-30-m { padding: 30px; }
  .pad-35-m { padding: 35px; }
  .pad-40-m { padding: 40px; }
  .pad-45-m { padding: 45px; }
  .pad-50-m { padding: 50px; }
  .pad-55-m { padding: 55px; }
  .pad-60-m { padding: 60px; }
}

// Desktop Helpers
@include breakpoint(large) {

  // General Resets
  .no-float-l { float: none; }
  .no-padding-l { padding: 0; }
  .no-margin-l { margin: 0; }


  // Display Types
  .none-l { display: none; }
  .block-l { display: block; }
  .inline-block-l { display: inline-block; }
  .inline-l { display: inline; }


  // Padding Helpers
  .pad-top-0-l { padding-top: 0; }
  .pad-top-5-l { padding-top: 5px; }
  .pad-top-10-l { padding-top: 10px; }
  .pad-top-15-l { padding-top: 15px; }
  .pad-top-20-l { padding-top: 20px; }
  .pad-top-25-l { padding-top: 25px; }
  .pad-top-30-l { padding-top: 30px; }
  .pad-top-35-l { padding-top: 35px; }
  .pad-top-40-l { padding-top: 40px; }
  .pad-top-45-l { padding-top: 45px; }
  .pad-top-50-l { padding-top: 50px; }
  .pad-top-55-l { padding-top: 55px; }
  .pad-top-60-l { padding-top: 60px; }
  .pad-bottom-0-l { padding-bottom: 0; }
  .pad-bottom-5-l { padding-bottom: 5px; }
  .pad-bottom-10-l { padding-bottom: 10px; }
  .pad-bottom-15-l { padding-bottom: 15px; }
  .pad-bottom-20-l { padding-bottom: 20px; }
  .pad-bottom-25-l { padding-bottom: 25px; }
  .pad-bottom-30-l { padding-bottom: 30px; }
  .pad-bottom-35-l { padding-bottom: 35px; }
  .pad-bottom-40-l { padding-bottom: 40px; }
  .pad-bottom-45-l { padding-bottom: 45px; }
  .pad-bottom-50-l { padding-bottom: 50px; }
  .pad-bottom-55-l { padding-bottom: 55px; }
  .pad-bottom-60-l { padding-bottom: 60px; }
  .pad-0-l { padding: 0; }
  .pad-5-l { padding: 5px; }
  .pad-10-l { padding: 10px; }
  .pad-15-l { padding: 15px; }
  .pad-20-l { padding: 20px; }
  .pad-25-l { padding: 25px; }
  .pad-30-l { padding: 30px; }
  .pad-35-l { padding: 35px; }
  .pad-40-l { padding: 40px; }
  .pad-45-l { padding: 45px; }
  .pad-50-l { padding: 50px; }
  .pad-55-l { padding: 55px; }
  .pad-60-l { padding: 60px; }
}

// HD Helpers
@include breakpoint(xlarge) {

  // General Resets
  .no-float-xl { float: none; }
  .no-padding-xl { padding: 0; }
  .no-margin-xl { margin: 0; }


  // Display Types
  .none-xl { display: none; }
  .block-xl { display: block; }
  .inline-block-xl { display: inline-block; }
  .inline-xl { display: inline; }


  // Padding Helpers
  .pad-top-0-xl { padding-top: 0; }
  .pad-top-5-xl { padding-top: 5px; }
  .pad-top-10-xl { padding-top: 10px; }
  .pad-top-15-xl { padding-top: 15px; }
  .pad-top-20-xl { padding-top: 20px; }
  .pad-top-25-xl { padding-top: 25px; }
  .pad-top-30-xl { padding-top: 30px; }
  .pad-top-35-xl { padding-top: 35px; }
  .pad-top-40-xl { padding-top: 40px; }
  .pad-top-45-xl { padding-top: 45px; }
  .pad-top-50-xl { padding-top: 50px; }
  .pad-top-55-xl { padding-top: 55px; }
  .pad-top-60-xl { padding-top: 60px; }
  .pad-bottom-0-xl { padding-bottom: 0; }
  .pad-bottom-5-xl { padding-bottom: 5px; }
  .pad-bottom-10-xl { padding-bottom: 10px; }
  .pad-bottom-15-xl { padding-bottom: 15px; }
  .pad-bottom-20-xl { padding-bottom: 20px; }
  .pad-bottom-25-xl { padding-bottom: 25px; }
  .pad-bottom-30-xl { padding-bottom: 30px; }
  .pad-bottom-35-xl { padding-bottom: 35px; }
  .pad-bottom-40-xl { padding-bottom: 40px; }
  .pad-bottom-45-xl { padding-bottom: 45px; }
  .pad-bottom-50-xl { padding-bottom: 50px; }
  .pad-bottom-55-xl { padding-bottom: 55px; }
  .pad-bottom-60-xl { padding-bottom: 60px; }
  .pad-0-xl { padding: 0; }
  .pad-5-xl { padding: 5px; }
  .pad-10-xl { padding: 10px; }
  .pad-15-xl { padding: 15px; }
  .pad-20-xl { padding: 20px; }
  .pad-25-xl { padding: 25px; }
  .pad-30-xl { padding: 30px; }
  .pad-35-xl { padding: 35px; }
  .pad-40-xl { padding: 40px; }
  .pad-45-xl { padding: 45px; }
  .pad-50-xl { padding: 50px; }
  .pad-55-xl { padding: 55px; }
  .pad-60-xl { padding: 60px; }
}