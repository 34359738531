@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'helpers';
@include motion-ui-transitions;
@include motion-ui-animations;
html {
    @include mui-fade(in);
}

body {
    a {
        font-weight: 600
    }
}

#my_wall_vxwpp {
    display: none;
    position: absolute;
    left: 10px;
    top: 50%;
    width: 42px;
    height: 160px;
    margin-top: -80px
}

#my_wall_vxwnp {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    width: 42px;
    height: 160px;
    margin-top: -80px
}

.vxwpp_h {
    background: url(../img/arrow_left_256_narrow.png) no-repeat center;
    background-size: 100%;
    width: 4%;
    height: 100%;
    left: 1%;
    top: 0
}

.vxwnp_h {
    background: url(../img/arrow_right_256_narrow.png) no-repeat center;
    background-size: 100%;
    width: 4%;
    height: 100%;
    right: 1%;
    top: 0
}

.vxwplayerviewmask {
    background-color: #FFFFFF
}


/* No changes */

.ifexpand {
    border: 0px;
    width: 100%;
    height: 100%;
}


/* Adjust to position close within the iframe */

.ifclose {
    top: 0px;
    left: 0px;
    /*height: 20px;*/
    /*width: 100%;*/
    color: #ffffff;
    font-weight: 900;
    font-size: 20px;
    border: 0px;
    background: #888888;
    opacity: 0.8;
    position: absolute;
}


/* Only adjust border, colors */

.ifdiv {
    border: 20px #FFFFFF solid;
    border-top: 20px #FFFFFF solid;
    border-bottom: 20px #FFFFFF solid;
    z-index: 10;
    display: none;
    position: absolute;
}

.no-js {
    @include breakpoint(small only) {
        .top-bar {
            display: none;
        }
    }
    @include breakpoint(medium) {
        .title-bar {
            display: none;
        }
    }
}

.main-menu {
    a {
        @extend .font-700
    }
    @include breakpoint(medium down) {
        li {
            border-bottom: 1px solid $medium-gray;
        }
    }
}

.banner {
    background-color: #415965;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23ffffff' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E");
    min-height: inherit !important;
    h2,
    p {
        color: $white
    }
    a {
        font-weight: bold;
        color: $white
    }
    text-align: center;
    padding: 30px 0 0 0;
    @include breakpoint(medium) {
        padding-top: 100px;
        padding-bottom: 50px;
        text-align: left;
    }
}

.logo {
    background-image: url("../img/vuemix_logo.svg");
    width: 244px;
    height: 71px;
    display: block;
    -webkit-transition: all .4s;
    transition: all .4s;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    @include breakpoint(medium) {
        margin-left: 0
    }
}

#my_wall {
    @include grid-column(12);
    min-height: 400px;
    padding: 0
}

#searchbox {
    margin: 0px auto;
    width: 99%;
}

.pitch {
    min-height: 400px;
    padding: 50px;
    &--grey {
        background: $light-gray !important;
    }
}

.footer {
    background-color: #415965;
    color: $white;
    h4,
    p {
        color: $white
    }
    a {
        font-weight: 600;
        color: $white;
    }
}

.lead {
    color: scale-color($dark-gray, $lightness: 30%);
}

.top-bar-wrapper {
    @include breakpoint(small only) {
        padding: 0
    }
}

.content {
    padding: 30px 0;
    @include breakpoint(medium) {
        padding: 60px 0;
        min-height: 600px
    }
}

.hero {
    position: relative;
    min-height: 200px;
    @include breakpoint(medium) {
        min-height: 500px;
    }
     background-image: url(../img/aboutus-hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    margin: 0 auto;
    h1 {
        color: #fff;
        @include vertical-center;
        @include horizontal-center;
        text-align: center
    }
    &--about {
        background-image: url(../img/aboutus-hero.jpg);

    }
    &--default {
        background-color: #415965;
        background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23ffffff' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E");
        min-height: 100px;
    }

}

.footer-copyright {
    background-color: scale-color(#415965, $lightness: -10%);
    p {
        color: $white;
        font-weight: bold
    }
}

.top-logo {
    a {
        @include breakpoint(small only) {
            text-align: center
        }
    }
}

.press-list{
    img {
        width: 100px
    }
}

.dropdown,
.accordion-menu {

    li:hover {
        background-color: $light-gray;
    }
}

.tabs-title {
    a {
        font-size:120%;
        font-weight:600
    }


}

#product-tabs {
     border:0;
     text-align: center;
     img {
         display:none;
     }

    li {
        background: $light-gray;
        border: 1px solid $medium-gray;
        width:100%;
        @include breakpoint(large up) {
            width:inherit;
            img {
                display: block;
                margin: 10px auto 0;

            }
        }
    }
    .last-tab {
          border-right: 1px solid $medium-gray;
    }
}

.tabs-content {
    border-top: 1px solid $medium-gray;
        img {
        display: block;
        margin:20px auto
    }
}

.tabs-title {
  display: inline-block;

  }

.tabs-content {
    @include breakpoint(medium) {
            padding-left: 5%;
            padding-right: 5%;
    }
     p {
        @include breakpoint(medium) {
            margin-bottom:50px
        }

     }
}


.top-bar-logo {
    img {
        width:115px;
        padding-top: 2px;
        display:none;
        @include breakpoint(medium) {
            display: inline;
        }
    }
}